<template>
  <div class="public-entrance">
    <router-view></router-view>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
@import "./less/teaching_public.less";
</style>
